import {GLOBAL_NAMESPACE} from '../../utils/getGlobal';
import {combineKeys} from '../../utils/namespaceUtils';
import {PersistentStorageService} from './PersistentStorageService';

export type Scope = 'sessionStorage' | 'localStorage';

const errorMessage = (scope: Scope) => `${scope} is not available`;

/**
 * there are two singleton instances: sessionStorage and localStorage, which are registered in bootstrap code.
 */
export class ClientPersistentStorageService
    implements PersistentStorageService {
    private storage: Storage;
    private scope: Scope;

    public constructor(scope: Scope) {
        this.storage = this.prepareStorage(scope);
        this.scope = scope;
    }
    public remove(key: string): void {
        if (!this.storage) {
            throw new Error(errorMessage(this.scope));
        }
        this.storage.removeItem(combineKeys([GLOBAL_NAMESPACE], key));
    }

    public set(key: string, data: string): void {
        if (!this.storage) {
            throw new Error(errorMessage(this.scope));
        }

        this.storage.setItem(combineKeys([GLOBAL_NAMESPACE], key), data);
    }

    public get(key: string): string | null {
        if (!this.storage) {
            throw new Error(errorMessage(this.scope));
        }

        return this.storage.getItem(combineKeys([GLOBAL_NAMESPACE], key));
    }

    private prepareStorage(scope: Scope): Storage {
        const storage = window[scope];
        if (!storage) {
            throw new Error(errorMessage(this.scope));
        }

        return storage;
    }
}
