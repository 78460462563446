import {SpaGlobalConfig} from '../../../generated/core';
import {isInBrowser} from '../../utils/browser/isInBrowser';

export function calculateRemoteMonitoringEnabled(config: SpaGlobalConfig) {
    return (factor: number = 1) => {
        const remoteEnabled =
            Math.random() * 1000000 < config.monitorProbability * factor;
        return (
            config.featureToggles.enableRemoteLogging &&
            remoteEnabled &&
            isInBrowser()
        );
    };
}
