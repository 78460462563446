import {
    ConsentConfiguration,
    PersonalizationConfig,
    SpaGlobalConfig
} from '../../../generated/core';
import {inject, singleton} from '../../infrastructure/di/annotations';
import {Logger} from '../logger/Logger';
import {
    SmaSi,
    SmartDigitalService,
    SETVWID_EVENT,
    ProfileV2,
    MetaData,
    LoadUserIdEvent
} from './SmartDigitalService';
import {getGlobal} from '../../utils/getGlobal';
import {isOptIn} from '../personalization/utils';

declare global {
    interface Window {
        smartSignals2?: SmaSi;
    }
}

@singleton('SmartDigitalService', {env: 'priority'})
export class PrioritySmartDigitalService implements SmartDigitalService {
    private readonly smasiDemoConfigKey: string = 'smaSiDemoEnabled';
    private readonly oneToOneDemoParam: string = '1to1-demo';

    @inject() private personalizationConfig!: PersonalizationConfig;
    @inject() private logger!: Logger;
    @inject() private consentConfiguration!: ConsentConfiguration;
    @inject() private spaGlobalConfig!: SpaGlobalConfig;
    private localUserId?: string;

    public get ready() {
        return !!this.localUserId;
    }

    public async getLocalProfile(pagePath: string): Promise<ProfileV2> {
        if (!isOptIn(this.spaGlobalConfig.featureToggles)) {
            return Promise.reject(
                new Error('SmaSi - consent for personalization not opt-ed in')
            );
        }

        if (!this.localUserId || this.isTemporaryLocalId()) {
            await this.loadLocalUserId();
        }

        return this.loadLocalProfile(pagePath);
    }

    public pushConsentAccepted(userId: string, consentVersion: string) {
        this.loadSmaSi().push({
            event: SETVWID_EVENT,
            payload: {
                consent: {
                    connectionId: this.consentConfiguration.connectionId,
                    externalId: userId,
                    consentType: '2',
                    consentVersion,
                    isActive: true,
                    validUntil: ''
                }
            }
        });
    }

    protected async fetch(
        input: RequestInfo,
        init?: RequestInit
    ): Promise<Response> {
        return window.fetch(input, init);
    }

    private loadSmaSi(): SmaSi {
        if (!window.smartSignals2) {
            window.smartSignals2 = [];
        }

        return window.smartSignals2;
    }

    private async loadLocalUserId(): Promise<void> {
        return new Promise(resolve => {
            this.loadSmaSi().push({
                $command: 'getUserId',
                onready: data => {
                    this.logger.personalization.debug(
                        'got user id',
                        data.userId
                    );
                    this.localUserId = data.userId;
                    resolve(undefined);
                }
            } as LoadUserIdEvent);
        });
    }

    private isTemporaryLocalId(): boolean {
        const id = this.localUserId;

        return Boolean(id && id.includes('*tmp*'));
    }

    private async loadLocalProfile(pagePath: string): Promise<ProfileV2> {
        if (this.isDemoModeEnabled()) {
            const profile = this.loadOneToOneDemoProfile();
            if (profile !== null) {
                return profile;
            }
        }

        const pageId = pagePath;
        if (!this.isConfigurationValid(pageId)) {
            const errorMsg =
                'Unable to prepare all data for VwPersonalization request';
            this.logger.personalization.warn(errorMsg);

            throw new Error(errorMsg);
        }
        const {
            smasiUrl,
            organizationId,
            collectionId,
            partnerId
        } = this.personalizationConfig;

        const url = new URL('/api/v2/user/VwPersonalization', smasiUrl);
        url.searchParams.append('organizationId', organizationId);
        url.searchParams.append('collectionId', collectionId);
        url.searchParams.append('partnerId', partnerId);

        url.searchParams.append('userId', this.localUserId as string);

        url.searchParams.append('pageId', pageId as string);

        return this.fetch(url.toString(), {
            method: 'get'
        })
            .then((response: Response) => {
                return response.json() as any;
            })
            .then((profile: any) => {
                return profile;
            });
    }

    private isConfigurationValid(pageId: string | null): boolean {
        const {
            smasiUrl,
            organizationId,
            collectionId,
            partnerId
        } = this.personalizationConfig;

        return Boolean(
            pageId && smasiUrl && organizationId && collectionId && partnerId
        );
    }

    private isDemoModeEnabled(): boolean {
        return getGlobal().config.getBoolean(this.smasiDemoConfigKey);
    }

    private loadOneToOneDemoProfile(): Promise<ProfileV2> | null {
        return this.loadDemoProfile(this.oneToOneDemoParam);
    }

    private loadDemoProfile(parameterName: string): Promise<ProfileV2> | null {
        const params = new URLSearchParams(window.location.search);
        if (params.has(parameterName)) {
            const encodedProfileString = params.get(parameterName);
            try {
                const decodedJsonString = decodeURIComponent(
                    encodedProfileString as string
                );
                const profilePersonalization = JSON.parse(decodedJsonString);
                const profile: ProfileV2 = {
                    personalization: profilePersonalization,
                    metadata: {} as MetaData
                };
                return Promise.resolve(profile);
            } catch (e) {
                const generalError = 'Invalid demo profile';
                const errorMessage =
                    e instanceof Error
                        ? `${generalError}: ${e.message}`
                        : generalError;

                this.logger.personalization.warn(errorMessage);
            }
        }

        return null;
    }
}
