import {PersonalizationConfig} from '../../../generated/core';
import {inject, singleton} from '../../infrastructure/di/annotations';
import {Logger} from '../logger/Logger';
import {Profile} from '../smartDigital/SmartDigitalService';
import {
    AdobeTarget,
    AdobeTargetService,
    GetOffersTargetResult,
    MboxResponse,
    MboxedOffers
} from './AdobeTargetService';
import {ConfigurableConsole} from '../logger/ConfigurableConsole';

export function processGetOffersResponse(
    data: GetOffersTargetResult,
    logger: ConfigurableConsole
): MboxedOffers[] {
    if (data && data.execute && data.execute.mboxes) {
        return data.execute.mboxes
            .filter((mbox: MboxResponse) => {
                if (!mbox.options) {
                    logger.warn("mbox doesn't have options", mbox.name);
                    return false;
                }
                return true;
            })
            .map((mbox: MboxResponse) => ({
                name: mbox.name,
                offers: mbox.options
                    ? mbox.options.filter(option => option.type === 'html')
                    : []
            }));
    }

    return [];
}

@singleton('AdobeTargetService', {env: 'priority'})
export class PriorityAdobeTargetService implements AdobeTargetService {
    @inject() private personalizationConfig!: PersonalizationConfig;
    @inject() private adobeTarget!: AdobeTarget;
    @inject() private logger!: Logger;

    public async getOffers(
        mboxNames: string[],
        profile?: Profile
    ): Promise<MboxedOffers[]> {
        this.logger.personalization.debug(
            'PriorityAdobeTargetService.getOffers',
            mboxNames,
            profile
        );
        if (!profile) {
            return [];
        }

        return this.adobeTarget
            .getOffers({
                request: {
                    execute: {
                        mboxes: mboxNames.map(
                            (name: string, index: number) => ({
                                name,
                                index,
                                profileParameters: profile
                            })
                        )
                    }
                },
                timeout: this.personalizationConfig.timeout
            })
            .then(data =>
                processGetOffersResponse(data, this.logger.personalization)
            );
    }
}
