import isEmpty from 'lodash.isempty';
import {SpaModel} from '../../generated/core';

export function parseSpaModel(): SpaModel {
    const spaEl = document.getElementById('spaModel');
    if (!spaEl) {
        throw new Error('Cannot find spa model!');
    }

    const model = JSON.parse(spaEl.innerHTML);

    if (isEmpty(model)) {
        throw new Error('Spa model is empty!');
    }

    return model;
}
