export const combineKeys = (...names: (string | string[])[]) => {
    let array: string[] = [];
    names.forEach((value: string | string[]) => {
        if (Array.isArray(value)) {
            array = array.concat(value);
        } else {
            array.push(value);
        }
    });

    return array.join('.');
};
