import {Logger} from '../context/logger/Logger';
import {ObjectOverwriting} from './registerConfiguration';

export const updateSingletonProperties = (
    map: (name: string) => any,
    logger: Logger,
    overwriting?: ObjectOverwriting
) => {
    if (!overwriting) {
        return;
    }
    Object.keys(overwriting).forEach(key => {
        const obj = map(key);
        if (obj) {
            const overwriteProp = overwriting[key];
            Object.keys(overwriteProp).forEach(prop => {
                obj[prop] = overwriteProp[prop];
                logger.general.debug('overwriting config %s.%s', key, prop);
            });
        } else {
            logger.general.error('no config found %s', key);
        }
    });
};
