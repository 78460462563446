import {inject, singleton} from '../../infrastructure/di/annotations';
import {Logger} from '../logger/Logger';
import {
    Adobe,
    AdobeTarget,
    GetOfferOptions,
    GetOffersOptions,
    GetOffersTargetResult
} from './AdobeTargetService';

declare global {
    interface Window {
        adobe?: Adobe;
        atjsLoaded?(): void;
    }
}

@singleton('AdobeTarget', {env: 'priority'})
export class PriorityAdobeTargetWrapper implements AdobeTarget {
    public VERSION: string = '0.0.0';
    @inject() private logger!: Logger;

    public async getOffer(options: GetOfferOptions): Promise<void> {
        this.logger.personalization.debug(
            'PriorityAdobeTargetWrapper.getOffer',
            options
        );
        const target = await this.loadAdobeTarget();
        target.getOffer(options);
    }

    public async getOffers(
        options: GetOffersOptions
    ): Promise<GetOffersTargetResult> {
        this.logger.personalization.debug(
            'PriorityAdobeTargetWrapper.getOffers',
            options
        );

        const target = await this.loadAdobeTarget();

        return target.getOffers(options);
    }

    private async loadAdobeTarget(): Promise<AdobeTarget> {
        if (window && window.adobe && window.adobe.target) {
            this.logger.personalization.debug(
                'PriorityAdobeTargetWrapper.loadAdobeTarget',
                window.adobe.target.VERSION
            );
            this.VERSION = window.adobe.target.VERSION;

            return Promise.resolve(window.adobe.target);
        }

        return this.resolveAdobeTarget();
    }

    private async resolveAdobeTarget(): Promise<AdobeTarget> {
        return new Promise<AdobeTarget>((resolve, reject) => {
            window.atjsLoaded = () => {
                if (window.adobe && window.adobe.target) {
                    this.VERSION = window.adobe.target.VERSION;
                    resolve(window.adobe.target);
                }
                reject(new Error('Adobe Target not loaded correctly'));
            };
        });
    }
}
