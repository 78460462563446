import {LogEntry, SingleLogEntry, MonitorLogEntry} from './HttpLogger';
import {sendMessage} from './sendMessage';

export interface BatchLogEntry extends LogEntry {
    type: 'batch';
    messages: (SingleLogEntry | MonitorLogEntry)[];
}

export class HttpQueuedLogger {
    public constructor(private url: string, private timeout: number = 0) {
        this.scheduleSendQueue();
    }

    private queue: (SingleLogEntry | MonitorLogEntry)[] = [];
    private monitorQueue = new Map<string, MonitorLogEntry>();

    public addLogMessage(message: SingleLogEntry): void {
        this.queue.push(message);
    }

    public addMonitorEvent(entry: MonitorLogEntry, hash: string) {
        this.monitorQueue.set(entry.monitorType + hash, entry);
    }

    public scheduleSendQueue(): void {
        if (this.timeout) {
            setTimeout(() => {
                this.sendQueue().catch(e =>
                    console.error('cannot send log events', e)
                );
                this.scheduleSendQueue();
            }, this.timeout);
        }
    }

    public sendQueue(): Promise<void> {
        if (!this.url) {
            console.error('no url for http logging configured');
            return Promise.resolve();
        }

        const queueCopy = this.queue.slice();
        this.queue = [];
        let index = 0;
        for (let entry of this.monitorQueue.values()) {
            index++;
            if (index <= 20) {
                queueCopy.push(entry);
            }
        }
        this.monitorQueue.clear();
        if (queueCopy.length > 0) {
            const event: BatchLogEntry = {
                type: 'batch',
                messages: queueCopy
            };

            return sendMessage(this.url, event);
        }

        return Promise.resolve();
    }
}
