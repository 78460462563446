import {TrackingDTO} from '../tracking/TrackingService';

export class TrackEventHolder {
    private events: LogTrackEvent[] = [];
    public track(event: TrackingDTO) {
        const logEvent = filtered(event);
        logEvent && this.events.push(logEvent);
        if (this.events.length > 10) {
            this.events.shift();
        }
    }
    public getLastEvents(count: number): LogTrackEvent[] {
        return this.events.filter(
            (_, idx) => idx >= this.events.length - count
        );
    }
}

function filtered(event: TrackingDTO): LogTrackEvent | undefined {
    if (
        event.eventInfo?.eventAction.match(/Click/) ||
        event.eventInfo?.eventAction === 'VWBasic_Pageload'
    ) {
        return {
            featureAppId: event?.environment?.featureAppId,
            eventAction: event?.eventInfo?.eventAction,
            url: event?.eventInfo?.pageId,
            contentId: event?.eventInfo?.contentId
        };
    }

    return undefined;
}

export interface LogTrackEvent {
    featureAppId?: string;
    eventAction: string;
    url?: string;
    contentId?: string;
}
