import {combineKeys} from '../../utils/namespaceUtils';
import {PersistentStorageService} from './PersistentStorageService';

/**
 * This Wrapper can be used to extend the namespace of persistent items.
 */
export class StorageWrapper implements PersistentStorageService {
    private storage: PersistentStorageService;
    private namespace: string[];

    public constructor(
        storage: PersistentStorageService,
        ...namespace: string[]
    ) {
        this.storage = storage;
        this.namespace = namespace;
    }
    public remove(key: string): void {
        return this.storage.remove(combineKeys(this.namespace, key));
    }

    public get(key: string): string | null {
        return this.storage.get(combineKeys(this.namespace, key));
    }

    public set(key: string, data: string): void {
        this.storage.set(combineKeys(this.namespace, key), data);
    }
}
