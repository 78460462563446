import * as lazySizes from 'lazysizes';
// lazySizes plugins have to be loaded after loading the core
import 'lazysizes/plugins/attrchange/ls.attrchange'; // listen to DOM attribute changes
import {Config} from './context/config/Config';
import {createLogOptions, Logger} from './context/logger/Logger';
import {ClientPersistentStorageService} from './context/persistentStorage/PriorityPersistentStorageService';
import {getOrCreateRegistry} from './infrastructure/di/Registry';
import {isDevMode} from './utils/devModeUtils';
import {getGlobal, initGlobal} from './utils/getGlobal';
import {isInBrowser} from './utils/browser/isInBrowser';
import {resolveLoadingCascadeCheckpoint} from './utils/loadingCascade';
import {parseSpaModel} from './utils/parseSpaModel';
import {registerSpaGlobalConfig} from './utils/registerConfiguration';
import {supportsWebP} from './utils/browser/supportsWebP';
import {SingleEntryPersistentStorageService} from './context/persistentStorage/SingleEntryPersistentStorageService';
import {TrackEventHolder} from './context/logger/TrackEventHolder';

function initLazySizes(): void {
    // lazySizes config - see https://github.com/aFarkas/lazysizes#js-api---options for further info
    const config = {
        // add custom settings here
    };

    // add config to global lazySizes config
    Object.assign(lazySizes.cfg, config);

    // event listener for lazysizes image load event
    function onLazybeforeunveil(evt: Event): void {
        // lazysizes doesn't provide a type def but we know the event target is the image to be loaded
        const htmlTarget = evt.target as HTMLElement;
        enableWebP(htmlTarget);
    }

    // check and enable WebP for images
    function enableWebP(element: HTMLElement): void {
        if (!supportsWebP()) {
            return;
        }
        const srcsetWebP = element.getAttribute('data-srcset-webp');
        if (srcsetWebP) {
            element.setAttribute('data-srcset', srcsetWebP);
        }
    }

    document.addEventListener('lazybeforeunveil', onLazybeforeunveil);

    // init lazySizes with custom configuration
    lazySizes.init();
}

function startSingletons(): void {
    const model = parseSpaModel();

    const trackEventHolder = new TrackEventHolder();
    const sessionStorage = new ClientPersistentStorageService('sessionStorage');
    const localStorage = new ClientPersistentStorageService('localStorage');
    const logger = new Logger(
        createLogOptions(
            model.spaGlobalConfig,
            trackEventHolder,
            SingleEntryPersistentStorageService.create('sessionStorage', 'log')
        )
    );
    const globalConfig = new Config(logger);
    initGlobal(globalConfig, logger);

    const registry = getOrCreateRegistry(logger);
    if (isDevMode(model.spaGlobalConfig)) {
        const timeout = getGlobal().config.getInt('personalization', 'timeout');
        if (timeout) {
            model.spaGlobalConfig.personalizationConfig.timeout = timeout;
        }
    }
    registerSpaGlobalConfig(registry, model.spaGlobalConfig, 'priority');
    const devMode = isDevMode(model.spaGlobalConfig);
    registry.addSingleton('SessionStorage', sessionStorage, {
        env: 'priority'
    });
    registry.addSingleton('TrackEventHolder', trackEventHolder, {
        env: 'priority'
    });
    registry.addSingleton('LocalStorage', localStorage, {env: 'priority'});
    registry.addSingleton('Logger', logger, {env: 'priority'});

    registry.initSingletons('priority', devMode);
    resolveLoadingCascadeCheckpoint('priorityExecuted');
}

function run(): void {
    if (isInBrowser()) {
        initLazySizes();
        window.vwa_d6_cms = window.vwa_d6_cms || {};
        window.vwa_d6_cms.init = startSingletons;
        resolveLoadingCascadeCheckpoint('priorityLoaded', startSingletons);
    }
}

run();
