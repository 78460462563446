import {GLOBAL_NAMESPACE} from '../../utils/getGlobal';
import {combineKeys} from '../../utils/namespaceUtils';
import {PersistentStorageService} from './PersistentStorageService';
import {Scope} from './PriorityPersistentStorageService';

const errorMessage = 'scope is not available';

function prepareStorage(scope: Scope): Storage {
    const storage = window[scope];
    if (!storage) {
        throw new Error(errorMessage);
    }

    return storage;
}
/**
 * there are two singleton instances: sessionStorage and localStorage, which are registered in bootstrap code.
 */
export class SingleEntryPersistentStorageService
    implements PersistentStorageService {
    private key: string;
    private entries?: {[key: string]: string};

    public static create(scope: Scope, key: string) {
        return new SingleEntryPersistentStorageService(
            prepareStorage(scope),
            key
        );
    }

    public constructor(private readonly storage: Storage, key: string) {
        this.key = combineKeys([GLOBAL_NAMESPACE, 'single'], key);
    }

    public remove(key: string): void {
        if (!this.storage) {
            throw new Error(errorMessage);
        }
        this.load();
        if (this.entries && this.entries[key]) {
            delete this.entries[key];
        }
        this.persist();
    }

    public set(key: string, data: string): void {
        if (!this.storage) {
            throw new Error(errorMessage);
        }
        this.load();
        if (this.entries) {
            this.entries[key] = data;
        }

        this.persist();
    }

    public get(key: string): string | null {
        if (!this.storage) {
            throw new Error(errorMessage);
        }
        this.load();

        return this.entries ? this.entries[key] : null;
    }

    private persist(): void {
        this.storage.setItem(this.key, JSON.stringify(this.entries));
    }

    private load() {
        if (!this.entries) {
            const value = this.storage.getItem(this.key);
            if (value) {
                try {
                    this.entries = JSON.parse(value);
                } catch (e) {
                    console.warn('cannot parse as json %s', value, e);
                    this.entries = {};
                }
            } else {
                this.entries = {};
            }
        }
    }
}
