import {singleton} from '../../infrastructure/di/annotations';
import {PrioritySmartDigitalService} from './PrioritySmartDigitalService';
import {ProfileV2} from './SmartDigitalService';

@singleton('SmartDigitalService', {env: 'priority', devMode: true})
export class PrioritySmartDigitalMock extends PrioritySmartDigitalService {
    private mockedProfile: ProfileV2 = {
        personalization: {segment: 'mock', count: '0'},
        metadata: {
            personalizationType: 'none',
            personalizationGroup: 'noPersonalization',
            recommendationId: 'recommendationId'
        }
    };
    protected async fetch(
        _input: RequestInfo,
        _init?: RequestInit
    ): Promise<Response> {
        this.mockedProfile.personalization.count += 1;
        this.mockedProfile.metadata.recommendationId =
            'recommendation' + this.mockedProfile.personalization.count;

        const mockResponse = new Response(
            JSON.stringify({...this.mockedProfile, type: '1:1'}),
            {
                headers: new Headers({
                    'Content-type': 'application/json'
                })
            }
        );

        return new Promise(resolve => {
            setTimeout(() => resolve(mockResponse), 40);
        });
    }
}
