import {GLOBAL_NAMESPACE} from '../../utils/getGlobal';
import {combineKeys} from '../../utils/namespaceUtils';
import {Logger} from '../logger/Logger';

export class Config {
    private readonly logger: Logger;

    public constructor(logger: Logger) {
        this.logger = logger;
    }

    public set(name: string | string[], value: any): void {
        localStorage.setItem(
            combineKeys([GLOBAL_NAMESPACE], 'config', name),
            String(value)
        );
    }
    public setJson(name: string | string[], value: any): void {
        localStorage.setItem(
            combineKeys([GLOBAL_NAMESPACE], 'config', name),
            JSON.stringify(value)
        );
    }
    public remove(...name: string[]): void {
        localStorage.removeItem(
            combineKeys([GLOBAL_NAMESPACE], 'config', name)
        );
    }
    public getBoolean(...name: string[]): boolean {
        const value = localStorage.getItem(
            combineKeys(GLOBAL_NAMESPACE, 'config', name)
        );

        return value === 'true';
    }
    public getInt(...name: string[]): number | null {
        const value = localStorage.getItem(
            combineKeys(GLOBAL_NAMESPACE, 'config', name)
        );

        return !!value ? parseInt(value, 10) : null;
    }
    public getJson(...name: string[]): any | null {
        const key = combineKeys(GLOBAL_NAMESPACE, 'config', name);
        const value = localStorage.getItem(key);

        try {
            return !!value ? JSON.parse(value) : null;
        } catch (e) {
            this.logger.general.error('cannot parse config %s', key, e);
        }
    }
    public get(...name: string[]): string | undefined | null {
        const value = localStorage.getItem(
            combineKeys(GLOBAL_NAMESPACE, 'config', name)
        );

        if (value === null) {
            return null;
        }

        return String(value);
    }
}
