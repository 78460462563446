export interface Profile {
    [key: string]: string;
}

export interface MetaData {
    personalizationType: string;
    personalizationGroup: string;
    recommendationId: string;
}

export interface ProfileV2 {
    personalization: Profile;
    metadata: MetaData;
}

export interface UserSegmentsResult {
    pocSegments: ProfileV2;
}

export const PERSONALIZATION_EVENT = 'getSegments';
export const SETVWID_EVENT = 'SetVWID';
export const GET_USER_ID_EVENT = 'getUserId';

export type GeneralSmasiEvent = SmaSiEvent | LoadUserIdEvent | SetVWIDEvent;

export interface SmaSiEvent {
    event: 'getSegments';
    payload: {
        returnPartnerSegments: boolean;
    };
    onready(userSegmentsResult: UserSegmentsResult): void;
}

export interface LoadUserIdEvent {
    $command: 'getUserId';
    onready(data: {userId: string}): void;
}

export interface SetVWIDEvent {
    event: 'SetVWID';
    payload: {
        consent: {
            connectionId: string;
            externalId: string;
            consentType: '2';
            consentVersion: string;
            isActive: true;
            validUntil?: '';
        };
    };
}

export interface SmaSi {
    push(event: GeneralSmasiEvent): void;
}

export interface SmartDigitalService {
    getLocalProfile(pagePath: string): Promise<ProfileV2>;
    pushConsentAccepted(userId: string, consentVersion: string): void;
    ready: boolean;
}
