import {SpaGlobalConfig} from '../../../generated/core';
import {isInBrowser} from '../../utils/browser/isInBrowser';

export function calculateRemoteLoggingEnabled(config: SpaGlobalConfig) {
    const remoteEnabled = Math.random() * 1000 < config.logProbability;
    const enabled =
        config.featureToggles.enableRemoteLogging &&
        remoteEnabled &&
        (isInBrowser() || config.ssrLoggerEnabled);
    return enabled;
}
