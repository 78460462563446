import {inject, singleton} from '../../infrastructure/di/annotations';
import {getGlobal} from '../../utils/getGlobal';
import {Logger} from '../logger/Logger';
import {
    AdobeTarget,
    GetOfferOptions,
    GetOffersOptions,
    GetOffersTargetResult,
    HtmlOffer,
    Offer
} from './AdobeTargetService';

interface OfferMock {
    contentUrl?: string;
    content?: string;
    action: string;
    selector: string;
}

const offerMockList: {[id: string]: OfferMock[]} = {
    '/content/evhub-sample/DE/de/magazine-overview-page/magazine-category-page/magazine-page-with-quotes.html': [
        {
            action: 'setContent',
            contentUrl:
                '/content/experience-fragments/evhub-sample/DE/de/personalized/editorial_stage/editorial_stage.html',
            selector:
                '#vwa_cms_slot_XX/de/magazine-overview-page/magazine-category-page/magazine-page-with-quotes/jcr:content/root/main/stageParsys/stage > DIV.simple_1488779093:eq(0) > DIV:nth-of-type(1)'
        }
    ],
    '/content/evhub-sample/DE/de.html': [
        {
            action: 'setContent',
            selector:
                '#vwa_cms_slot_XX/de/jcr:content/root/main/mainParsys/firstlevelteasersect/item_0 > DIV.simple:eq(0) > DIV:nth-of-type(1)',
            contentUrl:
                '/content/experience-fragments/evhub-sample/DE/de/static/editorial_teaser/samplefragment1/master.html'
        },

        {
            action: 'setContent',
            selector:
                '#vwa_cms_slot_XX/de/jcr:content/root/main/stageParsys/stage > DIV.simple:eq(0) > DIV:nth-of-type(1)',
            contentUrl:
                '/content/experience-fragments/evhub-sample/DE/de/personalized/editorial_stage/editorial_stage.html'
        },

        {
            action: 'setContent',
            selector:
                '#vwa_cms_insert_3_XX/de/jcr:content/root/main/mainParsys > DIV.simple:eq(0) > DIV:nth-of-type(1)',
            contentUrl:
                '/content/experience-fragments/evhub-sample/DE/de/personalized/focusteaser/focusteaser.html'
        },

        {
            action: 'setContent',
            selector:
                '#vwa_cms_slot_XX/de/jcr:content/root/main/mainParsys/editorialteasersecti > DIV.simple:eq(0) > DIV:nth-of-type(1)',
            contentUrl:
                '/content/experience-fragments/evhub-sample/DE/de/personalized/editorial-teaser-section/master.html'
        }
    ]
};

const EVHUB_SAMPLE_PATH = '/content/evhub-sample/DE';

async function fetchExperienceFragment(url: string): Promise<string> {
    return window.fetch(`${url}?nocache`).then(response => {
        return response.status === 200 ? response.text() : '';
    });
}

@singleton('AdobeTarget', {env: 'priority', devMode: true})
export class PriorityAdobeTargetMock implements AdobeTarget {
    public VERSION: string = '0.0.0';
    @inject() private logger!: Logger;

    public getOffer(options: GetOfferOptions): void {
        this.logger.personalization.info(
            `call target getOffer with ${JSON.stringify(options)}`
        );
        setTimeout(() => {
            const {success} = options;

            let id = window.location.pathname;
            let basePath = EVHUB_SAMPLE_PATH;

            const offerMocks: OfferMock[] = offerMockList[id];
            if (!offerMocks) {
                success([]);

                return;
            }
            const finalOfferMocks = offerMocks.map(offerMock => {
                const selector = offerMock.selector.replace('XX', basePath);

                return {
                    ...offerMock,
                    selector
                };
            });

            const promises: Promise<Offer>[] = [];
            finalOfferMocks.forEach(offerMock => {
                if (offerMock.content) {
                    promises.push(Promise.resolve(offerMock as Offer));
                } else if (offerMock.contentUrl) {
                    promises.push(
                        fetchExperienceFragment(offerMock.contentUrl).then(
                            text => {
                                return {
                                    selector: offerMock.selector,
                                    action: offerMock.action,
                                    content: text
                                };
                            }
                        ) as Promise<Offer>
                    );
                }
            });

            Promise.all(promises).then((offers: Offer[]) => {
                success(offers);
            });
        }, getGlobal().config.getInt('target', 'duration') || 40);
    }

    public async getOffers(
        options: GetOffersOptions
    ): Promise<GetOffersTargetResult> {
        this.logger.personalization.info(
            `call target getOffers mock with ${JSON.stringify(options)}`
        );

        const match = window.location.pathname.split('.html');
        if (match && match[0]) {
            const offersMockList: {
                [id: string]: string;
            } = await (
                await window.fetch(match[0] + '.target-personalization.json')
            ).json();

            const promises = options.request.execute.mboxes
                .filter(mbox => offersMockList[mbox.name])
                .map(async mbox =>
                    fetchExperienceFragment(offersMockList[mbox.name]).then(
                        (content: string) => {
                            const option: HtmlOffer = {type: 'html', content};

                            return {
                                index: mbox.index,
                                name: mbox.name,
                                options: [option]
                            };
                        }
                    )
                );

            const mboxes = await Promise.all(promises);

            return new Promise<GetOffersTargetResult>((resolve, reject) => {
                const failure = getGlobal().config.getBoolean(
                    'target',
                    'failure'
                );
                setTimeout(() => {
                    if (failure) {
                        reject('mock error');
                    }
                    if (!mboxes.length) {
                        resolve({});

                        return;
                    }
                    const mock: GetOffersTargetResult = {
                        execute: {
                            mboxes
                        }
                    };
                    resolve(mock);
                }, getGlobal().config.getInt('target', 'duration') || 40);
            });
        }
        return Promise.resolve({});
    }
}
