import {singleton} from '../../infrastructure/di/annotations';
import {
    GeneralSmasiEvent,
    GET_USER_ID_EVENT,
    PERSONALIZATION_EVENT,
    ProfileV2,
    SmaSi
} from './SmartDigitalService';

@singleton('SmasiMock', {env: 'priority', devMode: true})
export class PrioritySmaSiMock implements SmaSi {
    private mockedProfile: ProfileV2 = {
        personalization: {segment: 'mock', count: '0'},
        metadata: {
            personalizationType: 'none',
            personalizationGroup: 'noPersonalization',
            recommendationId: 'recommendationId'
        }
    };
    private count: number = 0;

    public constructor() {
        window.smartSignals2 = this;
    }

    public push(event: GeneralSmasiEvent): void {
        const result = {pocSegments: this.mockedProfile};

        if ('event' in event && event.event === PERSONALIZATION_EVENT) {
            console.log(`call smaSi ${event.event}`);
            this.count++;
            this.mockedProfile.personalization.count = String(this.count);
            setTimeout(() => event.onready(result), 40);
        }
        if ('$command' in event && event.$command === GET_USER_ID_EVENT) {
            console.log(`call smaSi ${event.$command}`);
            setTimeout(() => event.onready({userId: 'mockUser'}), 40);
        }
    }
}
